<template>
  <div class="bg-white rounded">
    <div class="grid grid-cols-1 py-5 px-3 md:grid-cols-2">
      <div>
        <TitlePlus title="Logs" :hide-plus="true" class="pl-2" />
      </div>

      <div class="flex md:justify-end">
        <date-range-picker
          :time-filter-enabled="true"
          @apply-date="onApplyFilterDateRange"
          @cancel-date="onApplyFilterDateRange"
        />
      </div>
    </div>

    <template>
      <FSTable
        :fst-id="fstId"
        :endpoint="getEndpoint"
        :isExportEnabled="false"
        :searchEnabled="false"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>
        <template #default="{ data }">
          <TimelineGroup
            v-if="data.length > 0"
            :logs="data"
            timeline-type="vehicle"
          />
        </template>
      </FSTable>
    </template>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import {
  FSTable,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import TimelineGroup from '@/components/timeline-new-final/TimelineGroup.vue'
import TitlePlus from '@/components/ui/TitlePlus'
export default {
  name: 'ViewVehicleLogs',

  components: {
    FSTable,
    FSTableFilter,
    TimelineGroup,
    TitlePlus,
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
  },

  computed: {
    reqId() {
      return this.$route.params.id
    },

    getEndpoint() {
      return useEndpoints.vehicle.logs(this.reqId)
    },
  },
  data() {
    return {
      fstId: 'vehicle-logs',
      filterOptions: [
        {
          key: 'request_title',
          type: 'checkbox',
          input: [
            { text: 'Unlock', value: 'UNLOCK' },
            { text: 'Lock', value: 'LOCK' },
            { text: 'Lock For Pause', value: 'LOCK_FOR_PAUSE' },
            { text: 'Unlock For Unpause', value: 'UNLOCK_FOR_UNPAUSE' },
            { text: 'Report', value: 'REPORT' },
            { text: 'Reserve Vehicle', value: 'RESERVE_VEHICLE' },
            { text: 'Force Lock', value: 'FORCE_LOCK' },
            { text: 'Force Unlock', value: 'FORCE_UNLOCK' },
            { text: 'Throttle On', value: 'THROTTLE_ON' },
            { text: 'Throttle Off', value: 'THROTTLE_OFF' },
            { text: 'Headlight On', value: 'HEADLIGHT_ON' },
            { text: 'Headlight Off', value: 'HEADLIGHT_OFF' },
            { text: 'Taillight On', value: 'TAILLIGHT_ON' },
            { text: 'Taillight Off', value: 'TAILLIGHT_OFF' },
            { text: 'Vehicle Status Sync', value: 'VEHICLE_STATUS_SYNC' },
            { text: 'Locked For Pause', value: 'LOCKED_FOR_PAUSE' },
            { text: 'Locked', value: 'LOCKED' },
            { text: 'Force Locked', value: 'FORCE_LOCKED' },
            { text: 'Unlocked For Pause', value: 'UNLOCKED_FOR_PAUSE' },
            { text: 'Unlocked', value: 'UNLOCKED' },
            { text: 'Force Unlocked', value: 'FORCE_UNLOCKED' },
            {
              text: 'Vehicle Outside Geofence',
              value: 'VEHICLE_OUTSIDE_GEOFENCE',
            },
            {
              text: 'Vehicle Inside Restricted Area',
              value: 'VEHICLE_INSIDE_RESTRICTED_AREA',
            },
            {
              text: 'Vehicle Inside Slowzone Area',
              value: 'VEHICLE_INSIDE_SLOWZONE_AREA',
            },
            {
              text: 'Trigger Payment By System',
              value: 'TRIGGER_PAYMENT_BY_SYSTEM',
            },
            {
              text: 'Vehicle Inside Geofence',
              value: 'VEHICLE_INSIDE_GEOFENCE',
            },
            {
              text: 'Vehicle Outside Restricted Area',
              value: 'VEHICLE_OUTSIDE_RESTRICTED_AREA',
            },
            {
              text: 'Vehicle Outside Slowzone Area',
              value: 'VEHICLE_OUTSIDE_SLOWZONE_AREA',
            },
            { text: 'Illegal Move', value: 'ILLEGAL_MOVE' },
            { text: 'Fallen Down', value: 'FALLEN_DOWN' },
            { text: 'IOT Removed', value: 'IOT_REMOVED' },
            { text: 'Critical Battery', value: 'CRITICAL_BATTERY' },
            { text: 'Pull Up', value: 'PULL_UP' },
            { text: 'Illegal Demolition', value: 'ILLEGAL_DEMOLITION' },
            { text: 'Idle Vehicle', value: 'IDLE_VEHICLE' },
            { text: 'Suspicious Unlock', value: 'SUSPICIOUS_UNLOCK' },
            {
              text: 'Illegal Movement by Distance',
              value: 'ILLEGAL_MOVEMENT_BY_DISTANCE',
            },
            { text: 'Active Vehicle', value: 'ACTIVE_VEHICLE' },
          ],
          title: 'Category',
        },
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Success', value: 'succeeded' },
            { text: 'Failed', value: 'failed' },
          ],
          title: 'Status',
        },
      ],
    }
  },
  methods: {
    onApplyFilterDateRange,
  },
}
</script>

<style lang="scss" scoped></style>
